/**
 * Resets
 */
*, :before, :after {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

input, select, button, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: none;
  font: inherit;
  color: inherit;
}

.jumbotron {
  background-color:transparent !important;
  padding: 0 !important;
}

/**
 * Base
 */
html {
  font: 112.5%/1.2 "Roboto", Arial, sans-serif;
  color: #3d464d;
  background-color: #fff;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow: hidden-x;
  text-align: center;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
}