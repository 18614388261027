.appHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.container {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /* max-width: 612px;
  max-width: 34rem; */
}

a {
  color: #3d464d;
  -webkit-transition: color 0.2s;
          transition: color 0.2s;
}

a:hover, a:focus {
  color: #267dc0;
}

h1 {
  font-size: 72px;
  font-size: 3rem;
  font-weight: 100;
  margin-bottom: .5em;
  color: #267dc0;
}

.headerTitle {
  margin-bottom: 0;
}

.subheader {
  font-weight: 300;
  margin-bottom: 1.5em;
}

.hint {
  color: #ccc;
  margin: 2em 0;
}

.footer {
  font-weight: 300;
  text-align: center;
}

.ubilabs {
  vertical-align: middle;
}
/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  padding: .5em 1em;
  box-shadow: 0px 0px 2px 0px #3d464d;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}


/**
 * Dropdown list styles
 */
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 460px;
}
.dd-wrapper {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dd-header {
  width: 100%;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 0px 0px 2px 0px #3d464d;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.dd-header span {
  margin-right: 20px;
}
.dd-header-title {
  font-weight: 300;
}

.dd-icon{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-list {
  z-index: 5;
  position: absolute;
  width: 100%;
  background-color: #fff;
  max-height: 25em;
  -webkit-overflow-scrolling: touch;  
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin-top: -1px;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}


.dd-list-item {
  
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.dd-list-item.selected {
  color: #fff;
  background-color: #ffcc01;
}
.dd-list-item:hover {
  background-color: #f5f5f5;
}


.dd-wrapper-single {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.dd-content {
  display: flex;
  align-items: center;
}

/**
 * Message alert and error styles
 */
.message-alert {
  color: #721c24;
  text-align: center;
}

/**
 * Google InfoWindows styles
 */
#iw-container {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-right: 10px;
}

#iw-container-img {
  padding-left: 0px;
  padding-right: 10px;
}

#iw-container-header {
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 15px;
	font-weight: 400;
	padding: 10px 10px 10px 0px;
  /*background-color: #267dc0;*/
  color: #267dc0;
	margin: 0;
	border-radius: 2px 2px 0 0;
}
#iw-container .iw-title {
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 15px;
	font-weight: 400;
	padding: 10px;
  /*background-color: #267dc0;*/
  color: #267dc0;
	margin: 0;
	border-radius: 2px 2px 0 0;
}
#iw-container .iw-content {
	font-size: 13px;
	line-height: 18px;
	font-weight: 400;
	margin-right: 1px;
	padding: 15px 5px 20px 15px;
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
}
.iw-content img {
	float: right;
  margin: 0 5px 5px 10px;
}

.iwt{
  display:block;
}

.iw-img {  
  max-height: 150px;
  width: 100%;
}

.gm-style .gm-style-iw-c {  
  border-radius: 3px;
}

.iw-subTitle {
	font-size: 13px;	
}
.iw-bottom-gradient {
	position: absolute;
	width: 326px;
	height: 25px;
	bottom: 10px;
	right: 18px;
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.social-button-align {
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}

.interest-list-title {  
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px 0px 10px;
  color: #267dc0;
  margin: 0;
  border-radius: 2px 2px 0 0;
}

.gm-style-iw {
  max-height: none !important;
}

.gm-style-iw-d {
  max-height: none !important;
  overflow: hidden !important;
}

.MuiRating-sizeSmall{
  font-size: .9em !important;
}